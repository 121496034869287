import React, { useEffect, useState } from 'react'
import '../styles/formStyle.css'
import { Avatar as Avtr } from '@material-ui/core'
import { isNil } from 'lodash'
import AvatarPlaceholder from '../../../assets/svg-icons/avatar-placeholder.svg'

const Avatar = props => {
  const avatarPath =
    !isNil(props.avatar) && !isNil(props.avatar.profile_img_url)
      ? props.avatar.profile_img_url
      : ''
  const [path, setPath] = useState(avatarPath)

  useEffect(() => {
    if (avatarPath !== '') {
      setPath(avatarPath)
    }
  }, [avatarPath])
  return (
    <>
      {path !== '' ? (
        <Avtr className={props.className} src={path} />
      ) : (
        <Avtr className={props.className} src={AvatarPlaceholder} />
      )}
    </>
  )
}
export default Avatar
