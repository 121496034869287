import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/layout'
import { connect } from 'redux-zero/react'
import actions from '../store/actions'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.css'
import Keys from '../utils/Keys'
import FullScreenLoader from '../components/FullScreenLoader'
import NotificationForm from '../components/Elements/Notification/NotificationForm'
import withRetailUsers from '../components/Elements/withRetailUsers'
import SelectUserModal from '../components/Elements/Notification/SelectUserModal'
const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`

const NotificationPage = props => {
  const [openUserSelect, setOpenUserSelect] = useState(false)

  const onUserSelect = uid => {
    if (typeof uid === 'string') {
      props.setNotificationSelectedUsers([
        ...props.notificationSelectedUsers,
        uid,
      ])
    } else {
      props.setNotificationSelectedUsers([
        ...props.notificationSelectedUsers,
        ...uid,
      ])
    }
  }

  const onUserRemove = uid => {
    let filtered
    if (typeof uid === 'string') {
      filtered = props.notificationSelectedUsers.filter(curSid => {
        return curSid !== uid
      })
    } else {
      filtered = props.notificationSelectedUsers.filter(curSid => {
        return !uid.includes(curSid)
      })
    }
    props.setNotificationSelectedUsers([...filtered])
  }

  return (
    <React.Fragment>
      <Layout>
        <HeaderStyled>
          <h1>Push Notifications</h1>
        </HeaderStyled>
        <br />
        <br />
        <NotificationForm
          userRemove={onUserRemove}
          selectedUsers={props.notificationSelectedUsers}
          allUsers={!openUserSelect}
          setOpenUserSelect={setOpenUserSelect}
        />
        <FullScreenLoader loading={props.loading} />
      </Layout>
      <SelectUserModal
        isOpen={openUserSelect}
        setOpenUserSelect={setOpenUserSelect}
        selectedUsers={props.notificationSelectedUsers}
        onUserSelect={onUserSelect}
        onUserRemove={onUserRemove}
      />
    </React.Fragment>
  )
}

export default withRetailUsers(NotificationPage)
