import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { PropTypes } from 'prop-types'
import Input from './Input'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))

const IconButtonWrapper = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <IconButton color={props.color} onClick={props.onClick}>
        {props.icon}
      </IconButton>
    </div>
  )
}

IconButtonWrapper.defaultProps = {
  color: 'default',
}

IconButtonWrapper.propTypes = {
  icon: PropTypes.any,
  color: PropTypes.string,
  onClick: PropTypes.func,
}

export default IconButtonWrapper
