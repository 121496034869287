import React from 'react'
import '../styles/formStyle.css'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import Input from '../FormElements/Input'
import Keys from '../../../utils/Keys'
import Button from '../Buttons/Button'
import Colors from '../../../utils/Colors'
import styled from 'styled-components'
import Select from '../FormElements/Select'
import Switch from '../FormElements/Switch'
import UserChip from './UserChip'
import { inReachOptional, isEmptyStr } from '../../../utils/taskHandler'
import { sendNotifications } from '../../../services/api'
import { Radio } from '@material-ui/core'
import IconButtonWrapper from '../FormElements/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/EditRounded'
const Colappse = styled.div`
  overflow: hidden;
  transition: max-height 0.4s ease-in-out; // note that we're transitioning max-height, not height!
  height: auto;
  max-height: ${props =>
    props.show ? '600px' : '0'}; // still have to hard-code a value!
`

class NotificationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationType: Keys.NOTIFICATION_GENERAL_TYPE,
      title: '',
      body: '',
      page: 'Feed',
      post_id: '',
      selectedUsers: [],
      subTitle: '',
      badge: '',
      sound: true,
    }
  }

  switchActive = e => {
    this.setState({ [e.target.value]: e.target.checked })
  }

  handleChange = event => {
    let val = event.target.value
    const name = event.target.name
    if (['notificationType'].includes(name)) {
      val = Number(event.target.value)
    }

    this.setState({
      [name]: val,
    })
  }

  handleSelect = event => {
    let localePermissions = this.state.page
    let options = event.target.options
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        localePermissions = options[i].value
      }
    }
    this.setState({ page: localePermissions })
  }

  handleResponse = response => {
    this.props.hideFullScreenLoader()
    if (response.status === 200) {
      this.props.handleAlert({
        text: 'a notification was successfully sent',
        type: 'success',
      })
      let userWithoutTokens = response.user_without_tokens
      if (userWithoutTokens.length > 7) {
        userWithoutTokens = userWithoutTokens.slice(0, 7)
      }
      userWithoutTokens.forEach(user => {
        this.props.handleAlert({
          text: `the user: ${user[Keys.FIRST_NAME]} ${
            user[Keys.LAST_NAME]
          } with uid: ${
            user[Keys.UID]
          } didn't had a push token, they need to login first to have a token`,
          type: Keys.ALERT_INFO,
        })
      })

      this.props.handleAlert({
        text: 'a notification was successfully sent',
        type: 'success',
      })
    } else {
      this.props.handleAlert({
        text: 'some error acured, so changes might not be saved',
        type: 'error',
      })
    }
  }

  printPageOptions = () => {
    const pages = Keys.PAGE_TYPES
    let options = []
    for (let page in pages) {
      options.push(
        <option id={page} key={page} value={page}>
          {page}
        </option>
      )
    }

    return options
  }

  handleSubmit = event => {
    event.preventDefault()
    const isConfirmed = confirm(
      'Are you sure you want to send the notification? '
    )
    if (isConfirmed) {
      const {
        notificationType,
        page,
        sound,
        title,
        body,
        subTitle,
        badge,
      } = this.state

      const { selectedUsers, selected_rid } = this.props

      let _sound = null
      let to = selectedUsers

      if (sound) {
        _sound = 'default'
      }

      if (selectedUsers.length === 0) {
        this.props.handleAlert({
          text: 'Houston we have a problem, You have to select some users',
          type: 'error',
        })
        return null
      } else {
        to = selectedUsers
      }

      const notification = inReachOptional(
        {
          to: to,
          body: body,
        },
        {
          sound: _sound,
          title: title,
          subtitle: subTitle,
        }
      )
      if (!isEmptyStr(badge)) {
        notification['badge'] = Number(badge)
      }

      if (notificationType === Keys.NOTIFICATION_PAGE_TYPE) {
        notification['data'] = { type: Keys.PAGE_TYPES[this.state.page] }
      }

      sendNotifications(this.handleResponse, {
        notification: notification,
        rid: selected_rid,
      })
    }
  }

  preventSubmit = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      e.preventDefault()
    }
  }

  handleAlignment = (e, alignment) => {
    this.setState({ notificationType: alignment })
  }

  render() {
    const buttons = [
      { value: Keys.NOTIFICATION_GENERAL_TYPE, child: 'General Notification' },
      { value: Keys.NOTIFICATION_PAGE_TYPE, child: 'Page Notification' },
      // { value: Keys.NOTIFICATION_POST_TYPE, child: 'Post Notification' },
      // { value: Keys.NOTIFICATION_TASK_TYPE, child: 'Task Notification' },
    ]
    const {
      title,
      body,
      notificationType,
      page,
      sound,
      subTitle,
      badge,
    } = this.state

    const { selectedUsers, selected_rid, cdn, avatar_path } = this.props
    return (
      <form
        className="itemForm"
        onSubmit={this.handleSubmit}
        onKeyPress={this.preventSubmit}
      >
        <ul className="formList">
          <span
            style={{
              margin: '0 7px 0 5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButtonWrapper
              onClick={e => {
                e.preventDefault()
                this.props.setOpenUserSelect(true)
              }}
              color={'primary'}
              icon={<EditIcon />}
            />
            User Stores
          </span>

          <div style={{ maxWidth: '80%', margin: 'auto' }}>
            {selectedUsers.length
              ? selectedUsers.map(uid => {
                  return (
                    <UserChip
                      key={uid}
                      remove={this.props.userRemove}
                      uid={uid}
                      selected_rid={selected_rid}
                      cdn={cdn}
                      avatar_path={avatar_path}
                    />
                  )
                })
              : 'No Users were selected'}
          </div>

          <Input
            label={'Title'}
            value={title}
            width={700}
            name={Keys.TITLE}
            onChange={this.handleChange}
          />
          <Input
            label={'Sub Title'}
            value={subTitle}
            width={700}
            name={'subTitle'}
            onChange={this.handleChange}
          />
          <Input
            label={'Body'}
            value={body}
            name={'body'}
            width={700}
            onChange={this.handleChange}
          />
          <Input
            label={'Badge count'}
            value={badge}
            name={'badge'}
            width={700}
            type={'number'}
            onChange={this.handleChange}
          />
          <Switch
            value={'sound'}
            label={'Sound'}
            onChange={this.switchActive}
            checked={sound}
            required={true}
          />
          <label>
            <Radio
              type="radio"
              color={'primary'}
              name={'notificationType'}
              onChange={this.handleChange}
              value={Keys.NOTIFICATION_GENERAL_TYPE}
              checked={notificationType === Keys.NOTIFICATION_GENERAL_TYPE}
            />
            {'General push notification'}
          </label>

          <label>
            <Radio
              type="radio"
              color={'primary'}
              name={'notificationType'}
              onChange={this.handleChange}
              value={Keys.NOTIFICATION_PAGE_TYPE}
              checked={notificationType === Keys.NOTIFICATION_PAGE_TYPE}
            />
            {'Open on Page push notification'}
          </label>

          <Colappse show={notificationType === Keys.NOTIFICATION_PAGE_TYPE}>
            <Select
              label={'Choose page'}
              value={page}
              onChange={this.handleSelect}
            >
              {this.printPageOptions()}
            </Select>
          </Colappse>

          <Button type={'submit'} label={'Send'} />
        </ul>
      </form>
    )
  }
}

const mapStateToProps = ({
  editPost,
  selectedPost,
  postRef,
  selected_rid,
  posts,
  users,
  cdn,
  avatar_path,
}) => ({
  editPost,
  selectedPost,
  postRef,
  selected_rid,
  posts,
  users,
  cdn,
  avatar_path,
})
export default connect(mapStateToProps, actions)(NotificationForm)
