import React from 'react'
import Avatar from '../../Elements/FormElements/Avatar'
import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import { formatAvatarPath } from '../../../utils/taskHandler'
import { makeStyles } from '@material-ui/core/styles'
const MyChip = styled(Chip)`
  margin: 2px;
`

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    position: 'inherit',
    '& > *': {
      margin: 'auto',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))

const UserChip = props => {
  const classes = useStyles()

  const handleDelete = () => {
    props.remove(props.uid)
  }

  return (
    <MyChip
      size="small"
      color="primary"
      variant="outlined"
      avatar={
        <Avatar
          className={classes.root}
          path={formatAvatarPath(
            props.cdn,
            props.avatar_path,
            props.selected_rid,
            props.uid
          )}
        />
      }
      label={props.uid}
      onDelete={handleDelete}
    />
  )
}

export default UserChip
