import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import FullScreenModal from '../FullScreenModal'
import SelectUserTable from './SelectUserTable'

const UserModal = props => {
  const close = () => {
    props.setOpenUserSelect(false)
  }

  return (
    <FullScreenModal
      isOpen={props.isOpen}
      close={close}
      header={'Select Users'}
    >
      <SelectUserTable
        selectedUsers={props.selectedUsers}
        onUserSelect={props.onUserSelect}
        onUserRemove={props.onUserRemove}
      />
    </FullScreenModal>
  )
}

const mapStateToProps = ({
  selectedUser,
  showUserModal,
  showDeleteUserWarningModal,
  editUser,
}) => ({
  selectedUser,
  showUserModal,
  showDeleteUserWarningModal,
  editUser,
})
export default connect(
  mapStateToProps,
  actions
)(UserModal)
