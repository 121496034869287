import React from 'react'
import PropTypes from 'prop-types'
import '../styles/formStyle.css'
import Colors from '../../../utils/Colors'
import styled from 'styled-components'
import { isNil } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import {
  convertUnderscoreNameToLabel,
  isSolid,
} from '../../../utils/taskHandler'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 220,
  },
}))

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`
const ButtonWrapper = styled.div`
  margin: 5px;
`

const Input = props => {
  const classes = useStyles()

  return (
    <Wrapper>
      <TextField
        disabled={props.readOnly}
        id={props.label}
        inputProps={{
          readOnly: props.readOnly,
          max: props.max,
          min: props.min,
          width: props.width,
          pattern: props.pattern,
        }}
        className={classes.textField}
        label={props.label}
        autoComplete={props.autoComplete}
        value={props.value}
        required={props.required}
        type={props.type}
        name={props.name}
        style={{ width: props.width }}
        onChange={props.onChange}
        margin="normal"
        helperText={props.helperText}
        InputLabelProps={{ shrink: true }}
      />
      {isSolid(props.children) ? (
        <ButtonWrapper>{props.children}</ButtonWrapper>
      ) : null}
    </Wrapper>
  )
}

Input.defaultProps = {
  step: null,
  max: null,
  min: null,
  key: null,
  pattern: null,
  required: false,
  type: 'text',
  readOnly: false,
  autoFocus: false,
  width: 200,
  helperText: null,
}

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
}

export default Input
