import React, { Component, useState } from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import { formatAvatarPath, isSolid } from '../../../utils/taskHandler'
import Keys from '../../../utils/Keys'
import { isNil } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '../../Elements/FormElements/Avatar'
import Input from '../../Elements/FormElements/Input'
import paginationFactory from 'react-bootstrap-table2-paginator'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    position: 'inherit',
    '& > *': {
      margin: 'auto',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))

const headerStyle = {
  textAlign: 'center',
  align: 'center',
  alignItems: 'center',
  alignContent: 'center',
  dataAlign: 'center',
  fontWeight: 'bold',
}

const SelectUserTable = props => {
  const classes = useStyles()
  const [searchQuery, setSearchQuery] = useState('')

  const _avatar = (cell, row) => {
    const { selected_rid, cdn, avatar_path } = props
    const uid = row[Keys.UID]
    return (
      <Avatar className={classes.root} selected_rid={selected_rid} uid={uid} />
    )
  }

  const searchResults = () => {
    const { users } = props
    return users.filter(user => {
      return (
        user[Keys.UID].startsWith(searchQuery) ||
        (!isNil(user[Keys.FIRST_NAME]) &&
          user[Keys.FIRST_NAME].startsWith(searchQuery)) ||
        (!isNil(user[Keys.LAST_NAME]) &&
          user[Keys.LAST_NAME].startsWith(searchQuery))
      )
    })
  }

  const handleSelectAll = isSelect => {
    const currentStoreRows =
      searchQuery.trim() !== '' ? searchResults() : props.users
    const uids = currentStoreRows.map(r => r[Keys.UID])

    if (isSelect) {
      props.onUserSelect(uids)
    } else {
      props.onUserRemove(uids)
    }
  }

  const options = {
    paginationSize: 4,
  }

  const columns = [
    {
      dataField: Keys.UID,
      text: 'User ID',
      headerStyle: headerStyle,
      align: 'center',
      sort: true,
    },
    {
      dataField: Keys.FIRST_NAME,
      text: 'First name',
      headerStyle: headerStyle,
      align: 'center',
      sort: true,
    },
    {
      dataField: Keys.LAST_NAME,
      text: 'Last name',
      headerStyle: headerStyle,
      align: 'center',
      sort: true,
    },
    {
      dataField: 'avatar',
      text: 'Avatar',
      isDummyField: true,
      headerStyle: headerStyle,
      align: 'center',
      formatter: _avatar,
    },
  ]

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: props.selectedUsers,
    onSelectAll: (isSelected, rows, e) => {
      handleSelectAll(isSelected)
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        props.onUserSelect(row[Keys.UID])
      } else {
        props.onUserRemove(row[Keys.UID])
      }
    },
  }

  return (
    <div className="tableWrapper">
      {!isSolid(props.users) ? (
        'Nothing to Show'
      ) : (
        <React.Fragment>
          <Input
            onChange={event => {
              setSearchQuery(event.target.value)
            }}
            label={'search'}
            value={searchQuery}
          />
          <BootstrapTable
            pagination={paginationFactory(options)}
            keyField="uid"
            data={searchQuery.trim() !== '' ? searchResults() : props.users}
            selectRow={selectRow}
            columns={columns}
            bordered={false}
          />
        </React.Fragment>
      )}
    </div>
  )
}

const mapStateToProps = ({
  users,
  permissions,
  userRoles,
  userRidsDetails,
  selected_rid,
  selected_sid,
  avatar_path,
  cdn,
  fetchedUsers,
}) => ({
  users,
  permissions,
  userRoles,
  userRidsDetails,
  selected_rid,
  selected_sid,
  avatar_path,
  cdn,
  fetchedUsers,
})

export default connect(
  mapStateToProps,
  actions
)(SelectUserTable)
